.tenants {
    @include sectionSpaceBottom();
    transition: background 0.2s ease-in-out;

   &[data-testimonial-bg='0'] {
    @include initTenantBG('../assets/images/bg-testimonial-01.jpg', '../assets/images/bg-testimonial-01-sm.jpg');
   }

   &[data-testimonial-bg='1'] {
    @include initTenantBG('../assets/images/bg-testimonial-02.jpg', '../assets/images/bg-testimonial-02-sm.jpg');
   }

   &[data-testimonial-bg='2'] {
    @include initTenantBG('../assets/images/bg-testimonial-03.jpg', '../assets/images/bg-testimonial-03-sm.jpg');
   }

   &[data-testimonial-bg='3'] {
    @include initTenantBG('../assets/images/bg-testimonial-04.jpg', '../assets/images/bg-testimonial-04-sm.jpg');
   }

   &[data-testimonial-bg='4'] {
    @include initTenantBG('../assets/images/bg-testimonial-05.jpg', '../assets/images/bg-testimonial-05-sm.jpg');
   }

   
   @include breakpointDown($bp-mobile-lg) {
    position: relative;
}


    &__header {
        background: $c-blue-2;
        color: $c-white;

        display: flex;
        justify-content: center;
        align-items: center;

        @include subheaderBar();

        h2 {
            margin: 0;
        }
    }

    &__content {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        max-height: 100%;

        width: 100%;
        max-width: $cn-inner-content;
        margin: auto;

        @include sectionPadding();
        
        padding-top: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;

        @include breakpointDown(1600px) {
            padding-right: 50px !important;
        }

        @include breakpointDown($bp-mobile-lg) {
            padding: 25px 0 0 0 !important;
        }
    }
}

.tenantsSwiper {
    margin: 0;
    height: 700px;
    background: none;
    width: 600px;

    display: flex !important;
    flex-flow: row nowrap;

    gap: 15px;

    
    @include breakpointDown($bp-mobile-lg) {
        position: unset;
    }

    @include breakpointDown($bp-mobile-lg) {
        width: auto;
        height: auto;
        flex-flow: column-reverse nowrap;
        padding-bottom: 25px;
        overflow-y: visible !important;

    }

    &__controls {
        width: 100px;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: 30px;

        @include breakpointDown($bp-mobile-lg) {
            flex-flow: row nowrap;
            justify-content: flex-end;
            width: 100%;
            height: auto;
            padding: 25px 10px;

            position: absolute;
            bottom: -40px;

            gap: 15px;

            #tenantsPrev, #tenantsNext {
                transform: rotate(0);
            }

        }
    }

    .tenantSlide {
        width: 100%;
        background: $c-white;
        opacity: 0.5;
        // @include slideFadeIn();
        height: auto !important;
        padding: 40px;

        

        @include breakpointDown($bp-mobile-lg) {
            height: 100%;
            max-width: 360px;
        }

        @include breakpointDown($bp-mobile-sm) {
            padding: 15px;
            max-width: 300px;
        }

        &__content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            text-align:start;
            height: 100%;
        }

        &__header {
            color: $c-blue-4;
            font-size: 22px;
        }

        &__subheader {
            color: $c-blue-4;
            margin-bottom: 15px;
            font-size: 20px;
        }
    }

    .swiper-button-prev {
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        transform: rotate(90deg);
        position: unset;

        &:hover, &:active {
            transform: rotate(90deg);
        }


    }

    .swiper-button-next {
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        transform: rotate(90deg);
        position: unset;

        &:hover, &:active {
            transform: rotate(90deg);
        }
    
    }
}
