.hero {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    
    @include breakpointUp(1200px) {
      max-height: 972px;
    }
    
    @include breakpointDown($bp-mobile-lg) {
        flex-flow: column-reverse nowrap;
    }

    p {
      font-size: 28px;
      line-height: 37px;
      margin-bottom: 50px;

      @include breakpointDown($bp-tablet-lg) {
        font-size: 22px;
        line-height: 30px;
      }

      @include breakpointDown($bp-mobile-lg) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 25px;
      }
    }

    &__content {
      background: $c-blue-grey;
      color: $c-white;

      flex: 1 0 48%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      padding-top: $nav-offset-desktop;

      @include breakpointDown($bp-tablet-lg) {
        padding-top: $nav-offset-tablet;
      }
    

      @include breakpointDown($bp-mobile-lg) {
        @include sectionPadding();
        padding-top: unset;
      }

      &-wrapper {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: flex-start;
    
          max-width: 750px;
          width: 100%;

          @include breakpointDown($bp-desktop-sm) {
            align-items: flex-start;
            padding-left: 50px;
            padding-right: 50px;
          }

          @include breakpointDown($bp-tablet-lg) {
            padding: 50px;
          }

          @include breakpointDown($bp-mobile-lg) {
            padding: 0;
          }
      }
    }


    &__copy {
      br {
        @include breakpointDown($bp-tablet-sm) {
          display: none !important;
        }
      }
    }

    &__gallery {
      flex: 1 0 48%;
      max-width: 50%;

      overflow: hidden;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      @include sectionPadding();
      
      padding-right: 0 !important;
      padding-top: $nav-offset-desktop;

      @include breakpointDown($bp-tablet-xl) {
        padding-top: $nav-offset-tablet;
        padding-left: 50px;
      }

      @include breakpointDown($bp-mobile-lg) {
        justify-content: center;
        align-items: center;
        max-width: unset;
        padding-left: 25px;
      }
    }

    &__tabs {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      color: $c-white;


      @include breakpointDown($bp-mobile-lg) {
        flex-flow: column nowrap;
      }
    }

    &__tab {
      height: 105px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;

      text-align: center;

      @include sectionPadding();

      &--desktop {
        display: block;

        @include breakpointDown($bp-mobile-lg) {
          display: none;
        }
      }

      &--mobile {
        display: none;
        
        @include breakpointDown($bp-mobile-lg) {
          display: block;
        }
      }

      p {
        font-size: 28px;
        line-height: 37px;
        margin: 0;
        text-align: center;
  
        @include breakpointDown($bp-tablet-lg) {
          font-size: 22px;
          line-height: 30px;
        }
  
        @include breakpointDown($bp-mobile-lg) {
          font-size: 18px;
          line-height: 22px;
        }
      }


      &:nth-child(1) { background: $c-blue-2 }
      &:nth-child(2) { background: $c-blue-3; max-width: 500px }
      &:nth-child(3) { background: $c-blue-4 }

      @include breakpointDown($bp-tablet-lg) {
        height: 85px;
      }

      @include breakpointDown($bp-mobile-lg) {
        flex: 0 0 50px;
        width: 100%;

        &:nth-child(2) { max-width: unset }
      }
    }
  }

  .heroSwiper {
    display: flex;
    justify-content: center;
    align-items: center;

    color: $c-blue-grey;
    margin: 0;

    height: 100%;

    .heroSlide {
      position: relative;
      background: $c-blue-1;

      display: flex;

      padding-left: 10%;
      width: 100%;

      opacity: 0;

      @include slideFadeIn();

      @include breakpointDown($bp-mobile-lg) {
        padding-left: 0;
        justify-content: center;
      }

      h4 {
        color: inherit;
        margin-bottom: 0;
      }

      img {
        object-fit: cover;
        object-position: center left;
        min-width: 200px;
        margin-right: -10%;
        margin-bottom: 10%;

        @include breakpointDown($bp-mobile-lg) {
          margin-bottom: 10%;
          max-height: 500px;
        }

        @include breakpointDown($bp-mobile-md) {
          margin-bottom: 20%;
        }

      }

      &__caption {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    
    .heroSlide.swiper-slide-active {
      opacity: 1;
  }

    .swiper-button-prev {
      top: 10%;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .swiper-button-next {
      top: 10%;
      bottom: 0;
      left: 85px;
      right: 0;

      @include breakpointDown($bp-mobile-lg) {
        left: 65px;
      }
    }



  }