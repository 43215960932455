.btn {
    background: $c-red;
    color: $c-white;

    display: inline-block;

    border-radius: 25px;
    border: none;
    padding: 10px 25px;

    user-select: none;

    @include btnTransition();
    @include activeResponse();



    &:hover {
        background: $c-blue-4;
    }
}