.contact {
    max-width: 2560px;
    margin: auto;

    &__bg {
        background: url('../assets/images/bg-contact-1920-02.jpg');
        background-size:auto;
        background-position: bottom right;
        background-repeat: no-repeat;
    
        @include breakpointDown(1440px) {
            background: $c-blue-1
        }
    }

    &__response {
        opacity: 0;
    }

    #contactResponse {
        opacity: 0;
        color:$c-blue-grey;
        height: 50px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: opacity 0.2s ease-in-out;

        &.active {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }
    }

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;

        background: $c-blue-2;
        color: $c-white;

        @include subheaderBar();

        h2 {
            margin: 0;
        }
    }
    
    &__content {
        display: flex;
        flex-flow: column nowrap;
        gap: 75px;

        width: 100%;
        max-width: $cn-inner-content;
        margin: auto;

        @include sectionPadding();

        @include breakpointDown($bp-mobile-lg) {
            gap: 50px;
        }
    }

    &__items {
        display: flex;
        flex-flow: row nowrap;
        gap: 15px;
        width: 100%; 
        
        @include breakpointDown(600px) {
            flex-flow: column nowrap;
        }
    }

    &-item {
        h4 {
            color:$c-blue-grey;
        }

        p {
            color:$c-blue-grey;
            margin-bottom: 15px;
        }

        font-size: 18px;
        line-height: 22px;
        
        @include breakpointDown($bp-mobile-lg) {
            font-size: 16px;
            line-height: 20px;
        }

        flex: 1 0 30%;
    }
    

    &__form {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        color: $c-blue-2;
        gap: 15px;

        .btn {
            width: auto;
            // margin: 50px auto 90px auto;
        }

        &-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            gap: 15px;
            width: 100%;

            @include breakpointDown($bp-tablet-sm) {
                flex-flow: column nowrap;
            }
        }
        
        textarea {
            height: 150px;
            padding: 15px;
            flex: 1 0 100%;

            background: rgba(255, 255, 255, 0.85);

            font-family: inherit;
            width: 100%;
            border-radius: 5px;
            resize: none;
            border: 0;

        }

        .form__input {
            background: rgba(255, 255, 255, 0.85);
            color: $c-blue-4;

            font-family: inherit;
            width: 100%;
            border-radius: 5px;
            border: none;

            padding: 15px;

            flex: 1 0 30%;
            height: 60px;

            &::placeholder {
                color: $c-blue-4;
            }
        }
    }
}

// Footer
.footer {
    background:$c-blue-grey;
    // border-top: 2px solid $c-white;
    color: $c-white;
    padding: 25px;


    // @include sectionPadding();

    @include breakpointUp($bp-tablet-sm) {
        padding-left: unset;
        padding-right: unset;    
    }

    &__content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        max-width: $cn-inner-content;
        margin: auto;

        @include breakpointDown($bp-tablet-sm) {
            flex-flow: column nowrap;
            justify-content: center;
            gap: 25px;
        }

       

        &-block {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            text-align: center;

            flex: 0 0 33%;

            @include breakpointDown($bp-tablet-sm) {
                flex: 1 0 100%;
            }

            img {
                // width: 100%;
                max-height: 50px;
            }
        }
    }

    &__utility {
        display: flex;
        justify-content: center;
        align-items: center;


        p {
            margin: 0;
        }

        @include breakpointUp($bp-tablet-sm) {
            min-width: max-content;
        }
 
    }
}