.company {
    color: $c-white;
    @include sectionSpaceBottom();

    &__content {
        background: url('../assets/images/bg-history.jpg');
        background-size: cover;
        background-position: top left;
        background-repeat: no-repeat;
    
        width: 100%;
        max-width: $cn-inner-content;
        margin: auto;

        display: flex;
        flex-flow: row nowrap;

        @include sectionPadding();

        @include breakpointDown($bp-tablet-lg) {
            background: unset;
            flex-flow: column nowrap;
            padding: unset;
        }
    }

    &__gallery {
        display: flex;
        justify-content: center;
        align-items: center;

        flex: 1 0 48%;

        max-width: 50%;
        padding: 25px;

        @include breakpointDown($bp-tablet-lg) {
            max-width: 100%;
        }
    }

    &__copy {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;

        flex: 1 0 48%;
        
        padding: 0 75px;

        @include breakpointDown($bp-tablet-lg) {
            color: $c-grey-4;

            h2 {
                color: $c-blue-grey;
            }
        }

        @include breakpointDown($bp-mobile-lg) {
            padding: 0 25px;
        }

    }
}

.companySwiper {
    position: relative;
    padding-bottom: 50px;

    // box-shadow: 10px 10px 15px 15px rgba(0, 0, 0, 0.1);

    .companySlide {
        display: flex;
        justify-content: center;
        opacity: 0;
        @include slideFadeIn();

        img {
            width: 100%;
        }
    }

    .companySlide.swiper-slide-active {
        opacity: 1;
    }

    @include breakpointDown($bp-tablet-lg) {
        max-width: 700px;
    }

    .swiper-pagination {
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 100%;
        z-index: 5;
        height: 50px;
    }

    .swiper-button-prev {
        background: none;
        transform: unset;
        position: absolute;
        top: 45%;
        left: 0;

        &:hover {
            background: none;
        }

    }

    .swiper-button-next {
        position: absolute;
        top: 45%;
        right: 0;
        background: none;
        transform: unset;

        &:hover {
            background: none;
        }

    }

    .swiper-pagination-bullet {
        background: white;

        @include breakpointDown($bp-tablet-lg) {
            background: black;
        }
    }

    .swiper-pagination-bullet-active {
        @include breakpointDown($bp-tablet-lg) {
            background: $c-blue-grey;
        }
    }
   
}