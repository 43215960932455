// Colours
$c-white: #FFFFFF;

$c-red: #D9091F;

$c-blue-1: #E4E8EC;
$c-blue-2: #93AABC;
$c-blue-2-opaque: #93AABC50;
$c-blue-3: #8097AB;
$c-blue-4: #738A9F;

$c-blue-grey: #3A4855;

$c-grey-1: #E2E2E2;
$c-grey-2: #A8A8A8;
$c-grey-3: #848484;
$c-grey-4: #707070;

// Containers
$cn-outer-content: 1920px;
$cn-inner-content: 1500px;

// Breakpoints
$bp-desktop-lg: 2560px;
$bp-desktop-sm: 1920px;

$bp-tablet-xl: 1400px;
$bp-mobile-nav: 1200px;
$bp-tablet-lg: 1280px;
$bp-tablet-sm: 1024px;

$bp-mobile-lg: 768px;
$bp-mobile-md: 500px;
$bp-mobile-sm: 390px;

$nav-offset-desktop: 169px;
$nav-offset-tablet: 100px;

// Z indexes
$z-nav: 10;