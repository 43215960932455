.mission {
    display: flex;
    justify-content: center;
    align-items: center;

    @include sectionPadding();
    @include copyLarger();

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        max-width: $cn-inner-content;

        text-align: center;

        @include breakpointDown($bp-mobile-lg) {
            flex-flow: column nowrap;
         }
    }

    &__mobile-image {
        display: none;
        width: 100%;

        @include breakpointDown($bp-mobile-lg) {
           display: block;
           margin-bottom: 25px;
        }

        img {
            width: 100%;
        }
    }

    &__images {
        flex: 1 1 23%;

        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        @include breakpointDown($bp-mobile-lg) {
           display: none;
        }
    }

    &__image {
        flex-shrink: 1;

        &-1 { align-self: flex-start; max-width: 100%; }
        &-2 { align-self: flex-end; max-width: 100%; }
        &-3 { align-self: flex-end; max-width: 100%; }
        &-4 { align-self: flex-start; max-width: 100%; }
    }

    &__copy {
        flex: 1 0 48%;
    }
}