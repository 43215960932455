.team {
    width: 100%;
    height: 1100px;
    max-width: $cn-inner-content;
    margin: auto;

    color: $c-white;

    background: url('../assets/images/bg-team.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;

    @include sectionPadding();
    @include sectionSpaceBottom();

    @include breakpointDown($bp-tablet-sm) {
        height: unset;
        padding: 50px;
    }

    &__content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 2.5%;


        @include breakpointDown($bp-tablet-sm) {
            flex-flow: column nowrap;
            height: unset;
            width: 100%;
            max-width: 500px;
        }
    }

    &__item {
        display: flex;
        flex-flow: column nowrap;

        flex: 1 1 18%;

        img {
            width: 100%;
            max-width: 233px;
            min-width: 133px;
        }

        &-text {
            margin-top: -20%;
            font-weight: 300;
        }

        &:nth-of-type(odd) {
            padding-bottom: 12.5%;

            @include breakpointDown($bp-tablet-sm) {
                align-self: flex-start;
                padding-bottom: unset;
            }
        }

        &:nth-of-type(even) {
            padding-top: 12.5%;

            @include breakpointDown($bp-tablet-sm) {
                padding-top: unset;
                align-self: flex-end;
            }
        }
    }
}