.commercial {
    @include sectionSpaceBottom();

    @include breakpointDown($bp-mobile-lg) {
        margin: 0;
    }
    
    &__content {
        display: flex;
        flex-flow: row nowrap;

        width: 100%;
        max-width: $cn-inner-content;
        margin: auto;

        @include breakpointDown($bp-mobile-lg) {
            flex-flow: column nowrap;
        }
    }

    &__header {
        background: url('../assets/images/img-residential-parallax.jpg');
        background-size: cover;
        background-position: top 0% center;
        background-repeat: no-repeat;

        flex: 1 0 48%;

        color: $c-white;


        @include sectionPadding();

        &-wrapper {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            height: 100%;
        }

        &-copy {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            @include copyLarger();
            
            img {
                @include breakpointDown($bp-mobile-lg) {
                    max-width: 50px;
                }
            }
        }
    }

    &__copy {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 0 48%;

       padding: 0 75px;

       @include breakpointDown($bp-tablet-sm) {
            padding: 0 50px;
        }

       @include breakpointDown($bp-mobile-lg) {
            padding: 25px;
        }

        &-text {
            h4 {
                color: $c-blue-2;
            }
        }

        &-col {
            display: flex;
            flex-flow: row nowrap;
        }

        &-col:nth-child(2) {
            img {
                max-width: 100%;
            }
            justify-content: center;
        }       

        &-img {
            flex: 1 1 13%;

            img {
                max-width: 100px;

                @include breakpointDown($bp-mobile-md) {
                    max-width: 75px;

                }
            }

      
        }

        &-copy {
            flex: 1 0 83%;

            display: flex;
            flex-flow: row nowrap;
        }
    }
}