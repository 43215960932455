.map {
    display: flex;
    flex-flow: row nowrap;

    color: $c-white;

    @include breakpointDown($bp-mobile-lg) {
        flex-flow: column nowrap;
    }

    &__copy {
        flex: 1 0 48%;

        background: url('../assets/images/img-residential-parallax.jpg');
        background-size: cover;
        background-position: top 0% center;
        background-repeat: no-repeat;

        height: 700px;
        min-height: max-content;

        @include sectionPadding();
        @include copyLarger();
        
        @include breakpointDown($bp-tablet-lg) {
            height: 466px;
        }

        @include breakpointDown($bp-mobile-lg) {
            height: unset;
            width: 100%;
            flex: unset;
        }


     

        &-wrapper {
            max-width: 650px;
            height: 100%;

            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: space-between;

            margin: auto;
        }

        &-cta {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            width: 100%;

            img {
                @include breakpointDown($bp-mobile-lg) {
                    max-width: 50px;
                }
            }
        }
    }

    #googleMap {
        flex: 1 0 48%;
        width: 100%;
        height: 700px;

        @include sectionPadding();

        @include breakpointDown($bp-tablet-lg) {
            height: 466px;
        }

        @include breakpointDown($bp-mobile-lg) {
            height: 288px;
            width: 100%;
        }
    }
}