.about {
    color: $c-white;

    display: flex;
    flex-flow: row nowrap;

    @include sectionSpaceBottom();


    @include breakpointDown($bp-tablet-sm) {
        flex-flow: column nowrap;
    }


    &__content {
        background: $c-blue-2;

        flex: 1 0 48%;

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;


        @include sectionPadding();

    }

    &__copy {
        img {
            margin-bottom: 15px;
        }

        @include breakpointDown($bp-tablet-sm) {
            max-width: unset;
        }  
    }

    &__gallery {
        display: flex;
        justify-content: center;
        align-items: center;

        flex: 1 0 48%;
        max-width: 50%;

    
        @include breakpointDown($bp-tablet-sm) {
            max-width: unset;
        }  

    }
}


.aboutSwiper {
    width: 100%;
    height: 100%;

    @include breakpointDown($bp-tablet-sm) {
        height: 400px;
    }  

    .aboutSlide {
        height: 100%;
        width: 100%;
        opacity: 0;
        @include slideFadeIn();

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .swiper-pagination-bullet {
        background: $c-blue-2;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        background: $c-white;
    }

    .swiper-button-prev {
        background: none;
        transform: unset;
        position: absolute;
        top: 45%;
        left: 0;

        &:hover {
            background: none;
        }

    }

    .swiper-button-next {
        position: absolute;
        top: 45%;
        right: 0;
        background: none;
        transform: unset;

        &:hover {
            background: none;
        }

    }
}