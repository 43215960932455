* {
    padding: 0;
    margin: 0;
    box-sizing: border-box !important;
}

.container {
    max-width: 2560px;
    position: relative;
    margin: auto;
}

html, body {
    scroll-behavior: smooth;
}

h1, h2, h3, img, span {
    user-select: none;
}

body {
    background: $c-blue-1;
    color: $c-grey-4;
}

h1, h2, h3 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
}

h1 {
    font-size: 70px;
    line-height: 80px;
    margin-bottom: 85px;

    @include breakpointDown($bp-desktop-sm) {
        font-size: 50px;
        line-height: 58px;    
    }

    @include breakpointDown($bp-tablet-lg) {
        margin-bottom: 50px;
    }

    @include breakpointDown($bp-mobile-lg) {
        font-size: 36px;
        line-height: 42px;   
        margin-bottom: 20px; 
    }
}

h2 {
    font-size: 60px;
    line-height: 70px;

    margin-bottom: 55px;

    @include breakpointDown($bp-tablet-lg) {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 45px;
    }

    @include breakpointDown($bp-mobile-lg) {
        font-size: 30px;
        line-height: 36px;    
        margin-bottom: 30px;
    }
}

h3 {
    font-size: 46px;
    line-height: 51px;

    @include breakpointDown($bp-tablet-lg) {
        font-size: 39px;
        line-height: 43px;
    }

    @include breakpointDown($bp-mobile-lg) {
        font-size: 30px;
        line-height: 32px;    
    }
}

h4 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: $c-white;

    font-size: 20px;
    line-height: 28px;

    margin-bottom: 15px;

    @include breakpointDown($bp-tablet-lg) {
        // font-size: 45px;
        // line-height: 55px;    
    }

    @include breakpointDown($bp-mobile-lg) {
        // font-size: 30px;
        // line-height: 36px;    
    }
}


p {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;

    @include breakpointDown($bp-tablet-lg) {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 25px;
    }

    @include breakpointDown($bp-mobile-lg) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

a {
    color: inherit;
    text-decoration: none;
}

.ico-section {

    &__tall {
        height: 150px;
        width: auto;
        margin-right: 35px;

        @include breakpointDown($bp-tablet-lg) {
            margin-bottom: 45px;
        }
    
        @include breakpointDown($bp-mobile-lg) {
            margin-bottom: 30px;
        }
    
    }

    &__wide {
        width: 170px;
        height: auto;
        margin-right: 25px;

        @include breakpointDown($bp-tablet-lg) {
            margin-bottom: 45px;
        }
    
        @include breakpointDown($bp-mobile-lg) {
            margin-bottom: 30px;
        }
    
    }
}

section {
    @include navScrollOffset();
}

#commercialGallery a {
    display: none;
}

.sl-overlay {
    color: #3A4855 !important;
}

.sl-close, .sl-next, .sl-prev {
    color: #fff !important;
}