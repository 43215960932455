.properties {
    @include sectionSpaceBottom();

    &__content {
        width: 100%;
        max-width: $cn-inner-content;
        margin: auto;
    }

    .propertiesSwiper {
        padding-bottom: 50px;

        h4 {
            margin-bottom: 0;
        }

        .swiper-pagination-bullet {
            color: $c-blue-2-opaque;
            height: 15px;
            width: 15px;
            margin: 0 7.5px;
        } 
        
        .swiper-pagination-bullet-active {
            background: $c-blue-grey;
        }

        .swiper-pagination {
            bottom: 0;
        }

        .swiper-button-next {
                right: 5px;
                bottom: 25px;

                height: 50px;
                width: 50px;
          
                &::after {
                    font-size: 20px;
                }
          
        }
        .swiper-button-prev {
                right: 70px;
                bottom: 25px;
        
                height: 50px;
                width: 50px;
          
                &::after {
                    font-size: 20px;
                }
          
            }

    }

    .propertiesSlide {
        &__content {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;    

            padding: 25px 50px;
            width: 100%;
        }

        color: $c-white;
        height: 380px;
        width: 500px;

        @include breakpointDown($bp-mobile-lg) {
            width: 100%;
        }

        &[data-property="beckwith"]  {
            background: url('../assets/images/img-residential-slider-01.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    
        &[data-property="settlers"]  {
            background: url('../assets/images/img-residential-slider-02.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    
        &[data-property="trailside"]  {
            background: url('../assets/images/img-residential-slider-03.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &[data-property="collegiate"]  {
            background: url('../assets/images/img-residential-slider-04.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    
    }
}