.nav {
    z-index: $z-nav;
    transition: background 0.2s;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: $nav-offset-desktop;


    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;

    @include breakpointDown($bp-tablet-xl) {
        height: $nav-offset-tablet;
  
    }

    @include breakpointDown($bp-mobile-nav) {
      height: unset;
      flex-flow: column nowrap;
      background: $c-blue-grey;

      .nav__items .menuActive {
        height: 100%;
      }
    }

    &__logo {
      height: 100%;
      padding: 25px 0 25px 0;

      img {
        transition: height 0.2s ease-in-out;
        
        @include breakpointDown($bp-desktop-sm) {
          transition: height 0.2s ease-in-out;

            height: 50px;
            margin-left: 50px;
        }

        @include breakpointDown($bp-mobile-nav) {
            margin-left: 0;
        }
      }
      
      #navToggle {
        display: none;
      }

      @include breakpointDown($bp-desktop-sm) {
        align-items: flex-start;
        // padding: 0 0 0 50px;
      }

      @include breakpointDown($bp-mobile-nav) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        padding: 10px 50px;
        background: $c-blue-grey;

        #navToggle {
          display: block;
        }  
      }

      @include breakpointDown(500px) {
        padding: 10px;
        transition: height 0.2s ease-in-out;


        img {
          height: 50px;
          transition: height 0.2s ease-in-out;

        }
      }
    }

    &__items {
      width: 50%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      padding-left: 100px;
   
      @include breakpointDown($bp-tablet-xl) {
        padding-left: 50px;
      }
      @include breakpointDown($bp-tablet-lg) {
        padding-left: 25px;
      }

      @include breakpointDown($bp-mobile-nav) {
        display: none;
        flex-flow: column nowrap;
        width: 100%;
        height: 100%;
        gap: 0;
        padding: 0 !important;
      }
    }

    &__items.active {
      display: flex;
      height: 100%;

      @include breakpointDown($bp-mobile-nav) {
        .nav__phone {
          box-shadow: 0 5px 15px rgb(0 0 0 / 25%);
        }
        }

    }
    
    &__content {
      max-width: $cn-inner-content;
      margin: auto;

      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      max-width: $cn-inner-content;

      @include breakpointDown($bp-mobile-nav) {
        flex-flow: column nowrap;
      }
    }

    &__phone {
      color: $c-red !important;

      @include breakpointDown($bp-mobile-nav) {
        background: $c-blue-1;
        flex-flow: column nowrap;

        &:hover {
          background: $c-blue-1 !important;
          color: $c-red !important;
        }
      }
    }

    &__link {
      color: $c-blue-grey;
      transition: color 0.2s;

      &:hover {
        color: #B3CAD9;
      }

      @include breakpointUp($bp-mobile-nav) {
        font-size: 20px;
      }

      @include breakpointDown($bp-mobile-nav) {
        color: $c-white;

        height: 80px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 36px;


        &:hover {
          background: $c-blue-2;
          color: $c-white;
        }
      }
    }

    &__link.active {
      color: $c-blue-4;
    }
}

.nav.sticky {
  background: $c-blue-grey;
  height: unset;
  transition: height 0.2s ease-in-out;


  .nav__logo img {
    transition: height 0.2s ease-in-out;
    height: 50px;
  }

  @include breakpointUp($bp-mobile-nav) {
    .nav__link {
      color: $c-white;

      &:hover {
        color: #B3CAD9;
      }
    }

    .nav__link.active {
      color: #93AABC;
    }
  }
}