@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Roboto+Condensed:wght@300&display=swap');

* {
    // outline: 1px solid red !important;
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }


  h1, h2 {
    font-family: 'Roboto Condensed', sans-serif;
  }

.red {
    color: $c-red;
  }

.blue4 {
    color: $c-blue-4;
  }

  .container__wrapper {
    background: $c-white;
    max-width: 1920px;
    margin: auto;
  }

  .swiper-button-prev {
    top: unset;
    bottom: 15px;
    left: unset;
    right: 100px;

    height: 73px;
    width: 73px;

    border-radius: 50%;

    background: $c-red;
    color: $c-white;

    // @include hoverResponse();
    // @include activeResponse();

    &:after {
      font-size: 30px;
      padding-right: 5px;
    }

    &:hover {
      background: $c-blue-grey;
    }

    @include breakpointDown($bp-mobile-lg) {
      height: 50px;
      width: 50px;

      &::after {
          font-size: 20px;
      }
    }
  }
  
  .swiper-button-next {
    top: unset;
    bottom: 15px;
    left: unset;
    right: 15px;

    height: 73px;
    width: 73px;

    border-radius: 50%;

    background: $c-red;
    color: $c-white;

    &:after {
      font-size: 30px;
      padding-left: 5px;
    }

    &:hover {
      background: $c-blue-grey;
    }

  
    @include breakpointDown($bp-mobile-lg) {
      height: 50px;
      width: 50px;

      &::after {
          font-size: 20px;
      }
    }
  }

  .swiper-pagination {
    z-index: 5 !important;
  }

  .swiper-slide-active, .swiper-slide-duplicate-active {
    opacity: 1 !important;
}